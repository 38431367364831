define("ember-tag-input/templates/components/tag-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8yzGDIEX",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[10,\"li\"],[14,0,\"emberTagInput-tag\"],[12],[1,\"\\n    \"],[18,3,[[30,1]]],[1,\"\\n\"],[41,[33,5],[[[1,\"      \"],[11,3],[24,0,\"emberTagInput-remove\"],[4,[38,6],[[30,0],\"removeTag\",[30,2]],null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13]],[1,2]],null],[10,\"li\"],[14,0,\"emberTagInput-new\"],[12],[1,\"\\n  \"],[10,\"input\"],[15,\"disabled\",[30,0,[\"readOnly\"]]],[15,0,[28,[37,7],[\"emberTagInput-input js-ember-tag-input-new\",[52,[33,8],\" is-disabled\"]],null]],[15,\"maxlength\",[30,0,[\"maxlength\"]]],[15,\"placeholder\",[30,0,[\"placeholder\"]]],[15,\"aria-label\",[30,0,[\"ariaLabel\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"tag\",\"index\",\"&default\"],false,[\"each\",\"-track-array\",\"tags\",\"yield\",\"if\",\"_isRemoveButtonVisible\",\"action\",\"concat\",\"readOnly\"]]",
    "moduleName": "ember-tag-input/templates/components/tag-input.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});