define("ember-intl/-private/formatters/-format-datetime", ["exports", "fast-memoize", "ember-intl/-private/formatters/-base"], function (_exports, _fastMemoize, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /**
                                                                                                                                                                                                                                                                                                                                                                                             * Copyright 2015, Yahoo! Inc.
                                                                                                                                                                                                                                                                                                                                                                                             * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
                                                                                                                                                                                                                                                                                                                                                                                             */
  const DATE_TIME_OPTIONS = ['localeMatcher', 'formatMatcher', 'timeZone', 'hour12', 'weekday', 'era', 'year', 'month', 'day', 'hour', 'minute', 'second', 'timeZoneName', 'hourCycle'];

  /**
   * @private
   * @hide
   */
  class FormatDateTime extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "createNativeFormatter", (0, _fastMemoize.default)((locales, options) => {
        return new Intl.DateTimeFormat(locales, options);
      }));
    }
    get options() {
      return DATE_TIME_OPTIONS;
    }
    format(locale, value, formatOptions) {
      const formatterOptions = this.readOptions(formatOptions);
      this.validateFormatterOptions(locale, formatterOptions);
      const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
      return formatterInstance.format(new Date(value));
    }
  }
  _exports.default = FormatDateTime;
  _defineProperty(FormatDateTime, "type", void 0);
});